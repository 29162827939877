<template>
  <v-container
    grid-list-md
    text-xs-center>
    <v-layout
      row
      wrap>
      <v-flex
        xs12
        md12>
        <h3
          v-if="isSuccess && !isLoading"
          color="success">{{ $t('thirdParty.yourOrderSuccessfullyPaid') }}</h3>
        <v-btn
          v-if="!isLoading"
          color="#ff6d6d"
          style="color: #fff;"
          @click="onReturnHomeShopping()">
          {{ $t('thirdParty.continueShopping') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
export default {
  data: () => ({
    // returnUrl: null,
    isLoading: false,
    isSuccess: false
  }),
  created () {
    let dataParams = this.$route.query
    let filter = {
      params: {
        key: dataParams.key,
        data: dataParams.data
      }
    }
    this.isLoading = true
    this.GET_VI_VET_PAYMENT_RESULTS(filter).then(
      function (res) {
        // let data = res.data
        // this.returnUrl = data[8]
        this.isLoading = false
        this.isSuccess = true
        this.REMOVE_ALL_PRODUCT_FROM_CART()
      }.bind(this)
    ).catch(
      function () {
        this.isLoading = false
        this.isSuccess = false
        this.$router.push({
          path: '/lpb-info-invalid'
        })
      }.bind(this)
    )
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    /**
     * Return vi viet
     */
    onReturnHomeShopping: function () {
      this.$router.push({
        name: 'MobilePortalProductList',
        params: {
          'storeChannelId': this.storeChannelsIdInRouteParams()
        }
      })
      // window.open(this.returnUrl)
    },
    ...mapActions([
      'GET_VI_VET_PAYMENT_RESULTS',
      'REMOVE_ALL_PRODUCT_FROM_CART'
    ])
  }
}
</script>

<style>

</style>
